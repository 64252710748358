<template>
  <div>
    <v-card color="#F9F9FB" style="border-radius: 10px; padding: 50px">
      <v-card-subtitle
        class="d-flex justify-end pt-0"
        style="font-weight: 400; font-size: 13px; color: #49494b"
        ><span style="font-weight: 700; font-size: 13px; margin-right: 3px"
          >작성자</span
        >
        {{ fullName }}&nbsp;&nbsp;&nbsp;
        <span style="font-weight: 700; font-size: 13px; margin-right: 3px"
          >사업자</span
        >
        {{ companyName }}&nbsp;&nbsp;&nbsp;
        <span style="font-weight: 700; font-size: 13px; margin-right: 3px"
          >문의날짜</span
        >
        {{ dateCreated }}
      </v-card-subtitle>
      <v-card
        class="mb-4"
        style="border-radius: 16px; padding: 24px 50px"
        elevation="0"
        light
      >
        <v-chip
          v-if="inquiry.isReplied"
          class="mb-3"
          small
          text-color="white"
          :color="`#18CC8B`"
          >답변 완료</v-chip
        >
        <v-chip v-else class="mb-3" small text-color="black" :color="`#D7F9E8`"
          >미답변</v-chip
        >
        <v-card-subtitle
          class="font-weight-bold text--darken-1 pa-0 mb-3"
          style="color: #5f78ff; font-size: 16px"
        >
          {{
            inquiry.inquiryTypeId === 1
              ? "과금설정 변경"
              : inquiry.inquiryTypeId === 2
              ? "충전기 고장/수리완료"
              : inquiry.inquiryTypeId === 3
              ? "충전기 정보 수정"
              : inquiry.inquiryTypeId === 4
              ? "사업 제휴"
              : inquiry.inquiryTypeId === 5
              ? "매출 및 실적"
              : "기타 문의"
          }}
        </v-card-subtitle>
        <v-card-title class="font-weight-black pa-0">{{
          inquiry.title
        }}</v-card-title>
      </v-card>

      <v-card
        class="my-4"
        style="border-radius: 16px; padding: 40px 50px"
        elevation="0"
        min-height="400"
        height="100%"
        light
      >
        <v-card-subtitle
          style="font-size: 16px; font-weight: 800; color: black"
          class="pa-0 mb-4"
        >
          문의 내용
        </v-card-subtitle>
        <v-card-text
          style="font-size: 15px; font-weight: 500; color: black"
          class="pa-0"
          v-html="inquiry.contents"
        ></v-card-text>
      </v-card>

      <!-- 답변이 있을 경우 -->
      <div v-if="inquiry.isReplied === true && isUpdateOpen === false">
        <v-card
          class="my-4"
          style="border-radius: 16px; padding: 32px 50px 40px"
          elevation="0"
          light
        >
          <div class="d-flex justify-space-between">
            <v-card-subtitle
              style="font-size: 16px; font-weight: 800; color: black"
              class="pa-0 pt-2 mb-0"
            >
              답변 내용
            </v-card-subtitle>
            <!-- 답변날짜와 수정날짜가 다르다는 건 : 한번 이상 수정됐다는 의미 -->
            <v-card-subtitle
              v-if="reply.dateUpdated !== reply.dateCreated"
              class="pa-0"
              style="color: #73737b; font-weight: 400; font-size: 15px"
              ><span
                style="
                  color: #73737b;
                  font-weight: 700;
                  font-size: 15px;
                  margin-right: 3px;
                "
                >수정</span
              >
              {{
                new Date(reply.dateUpdated).toLocaleString()
              }}</v-card-subtitle
            >
          </div>
          <v-card-subtitle
            class="pa-0"
            style="color: #73737b; font-weight: 400; font-size: 15px"
            >{{ new Date(reply.dateCreated).toLocaleString() }}</v-card-subtitle
          >
          <!-- \n 개행처리 위해 v-html 사용 -->
          <v-card-subtitle
            class="px-0 mb-4"
            style="color: black; font-weight: 500; font-size: 15px"
            v-html="reply.contents"
          ></v-card-subtitle>

          <div class="d-flex justify-start align-center">
            <v-btn
              elevation="0"
              width="10px"
              class="mr-6"
              @click="isUpdateOpen = true"
              ><v-img
                src="@/assets/edit.png"
                height="100%"
                max-width="15px"
                class="mr-1"
              />수정</v-btn
            >
            <v-btn
              elevation="0"
              width="10px"
              @click="
                alertMessage = `답변을 삭제하시겠습니까?`;
                isDeleteAlertOpen = true;
              "
              ><v-img
                src="@/assets/trash.png"
                height="100%"
                max-width="15px"
                class="mr-1"
              />삭제</v-btn
            >
          </div>
        </v-card>

        <Button
          :btnName="`닫기`"
          :backgroundColor="`#F9F9FB`"
          :color="`#73737B`"
          :borderColor="`#E1E1E3`"
          :width="`131px`"
          :height="`50px`"
          :fontWeight="`400`"
          :fontSize="`16px`"
          class="d-flex justify-end"
          @click="close"
        />
      </div>

      <!-- 답변 수정중일 경우 isUpdateOpen -->
      <v-card
        v-if="isUpdateOpen === true"
        class="my-4"
        style="border-radius: 16px; padding: 40px 50px"
        elevation="0"
        height="100%"
        light
      >
        <v-card-subtitle
          style="font-size: 16px; font-weight: 800; color: black"
          class="pa-0 mb-4"
        >
          답변 수정
        </v-card-subtitle>
        <!-- 사용자가 수정할 때는 <br>태그를 다시 \n으로 변경 -->
        <v-textarea
          class="mb-4"
          outlined
          hide-details
          type="text"
          name="input-7-4"
          :value="reply.contents.split(`<br>`).join(`\n`)"
          @change="editTextArea"
        ></v-textarea>

        <div v-if="isUpdateOpen === true" class="d-flex justify-space-between">
          <Button
            :btnName="`취소`"
            :backgroundColor="`white`"
            :color="`#73737B`"
            :borderColor="`#E1E1E3`"
            :width="`131px`"
            :height="`50px`"
            :fontWeight="`400`"
            :fontSize="`16px`"
            @click="isUpdateOpen = false"
          />
          <Button
            :btnName="`수정 완료`"
            :backgroundColor="`black`"
            :color="`white`"
            :borderColor="`black`"
            :width="`131px`"
            :height="`50px`"
            :fontWeight="`800`"
            :fontSize="`16px`"
            @click="updateReply"
          />
        </div>
      </v-card>

      <!-- 답변이 없을 경우 -->
      <v-card
        v-if="inquiry.isReplied === false && isReplyOpen === true"
        class="my-4"
        style="border-radius: 16px; padding: 40px 50px"
        elevation="0"
        height="100%"
        light
      >
        <v-card-subtitle
          style="font-size: 16px; font-weight: 800; color: black"
          class="pa-0 mb-4"
        >
          답변하기
        </v-card-subtitle>
        <v-textarea
          class="mb-4"
          outlined
          hide-details
          type="text"
          name="input-7-4"
          @change="editTextArea"
        ></v-textarea>

        <div v-if="isReplyOpen === true" class="d-flex justify-space-between">
          <Button
            :btnName="`취소`"
            :backgroundColor="`white`"
            :color="`#73737B`"
            :borderColor="`#E1E1E3`"
            :width="`131px`"
            :height="`50px`"
            :fontWeight="`400`"
            :fontSize="`16px`"
            @click="isReplyOpen = false"
          />
          <Button
            :btnName="`답변등록`"
            :backgroundColor="`black`"
            :color="`white`"
            :borderColor="`black`"
            :width="`131px`"
            :height="`50px`"
            :fontWeight="`800`"
            :fontSize="`16px`"
            @click="
              alertMessage = `답변을 등록 하시겠습니까?`;
              isCreateAlertOpen = true;
            "
          />
        </div>
      </v-card>

      <!-- 답변이 없을 경우 -->
      <div
        v-if="inquiry.isReplied === false && isReplyOpen === false"
        class="d-flex justify-space-between"
      >
        <Button
          :btnName="`닫기`"
          :backgroundColor="`#F9F9FB`"
          :color="`#73737B`"
          :borderColor="`#E1E1E3`"
          :width="`131px`"
          :height="`50px`"
          :fontWeight="`400`"
          :fontSize="`16px`"
          @click="close"
        />
        <Button
          :btnName="`답변하기`"
          :backgroundColor="`black`"
          :color="`white`"
          :borderColor="`black`"
          :width="`131px`"
          :height="`50px`"
          :fontWeight="`800`"
          :fontSize="`16px`"
          @click="isReplyOpen = true"
        />
      </div>
    </v-card>

    <v-dialog v-model="isCreateAlertOpen" max-width="369">
      <Alert
        :message="alertMessage"
        :btnName1="`취소`"
        :btnName2="`답변등록`"
        :backgroundColor="`black`"
        @close="isCreateAlertOpen = false"
        @action="postReply"
      />
    </v-dialog>

    <v-dialog v-model="isDeleteAlertOpen" max-width="369">
      <Alert
        :message="alertMessage"
        :btnName1="`취소`"
        :btnName2="`삭제하기`"
        :backgroundColor="`#ED3535`"
        @close="isDeleteAlertOpen = false"
        @action="deleteReply"
      />
    </v-dialog>
  </div>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import Button from "@/app/common/Button.vue";
import Alert from "@/app/common/Alert.vue";

export default {
  name: "Inquiry",

  components: {
    Button: Button,
    Alert: Alert,
  },

  data() {
    return {
      inquiry: {}, // 1:1문의 상세
      fullName: "", // 작성자
      companyName: "", // 사업자
      dateCreated: "", // 문의날짜
      isReplyOpen: false, // 답변하기 UI 오픈
      contents: "", // 답변 내용
      isCreateAlertOpen: false, // 답변 등록 alert
      alertMessage: "", // alert 메세지
      reply: {}, // 답변
      isDeleteAlertOpen: false, // 답변 삭제 alert
      isUpdateOpen: false, // 답변 수정하기 UI 오픈
    };
  },

  methods: {
    // 답변에 변화가 생겼을 때 모두 이 메서드 타게함
    resetReply() {
      const payload = {
        inquiryId: this.inquiry.id,
      };
      OCPP_API.inquiries.searchReply(payload).then((res) => {
        this.reply = res.data._embedded.replies[0];
        this.reply.contents = this.reply.contents.split("\n").join("<br>"); // v-textarea 개행처리
        this.contents = this.reply.contents; // this.contents에 api에서 받아온 내용 담아놓기. 내용이 차이가 없어도 this.contents로 수정api 요청하기 위함.
      });
    },

    open(item) {
      // 답변하기 & 수정하기 버튼 눌렀을 때 UI 리셋
      this.isReplyOpen = false;
      this.isUpdateOpen = false;

      OCPP_API.inquiries
        .search({ id: item.id })
        .then((res) => {
          this.inquiry = res.data._embedded.inquiries[0];
          this.inquiry.contents = this.inquiry.contents
            .split("\n")
            .join("<br>"); // v-textarea 개행처리
        })
        .then(() => {
          this.resetReply(); // 상세를 클릭할 때마다 답변도 새로 api 요청
        });

      this.fullName = item.createUserId.fullName;
      this.companyName = item.createUserId.company.companyName;
      this.dateCreated = item.dateCreated.replace("T", " ").slice(0, 19);

      // 만약 답변완료인 글이라면 답변상세 조회 api 호출
      if (this.inquiry.isReplied === true) {
        this.resetReply();
      }
    },

    close() {
      this.$emit("close");
    },

    // 답변 글자 입력
    editTextArea(event) {
      this.contents = event; // textarea에 onchange될 때 this.contents에 담기
    },

    // 답변등록
    postReply() {
      const payload = {
        inquiryId: this.inquiry.id,
        contents: this.contents,
      };
      OCPP_API.inquiries
        .createReply(payload)
        .then(() => {
          this.isCreateAlertOpen = false;
        })
        .then(() => {
          this.inquiry.isReplied = true; // 답변이 있는 경우로 UI를 바꾸기 위함
        })
        .then(() => {
          this.resetReply();
        });
    },

    // 답변삭제
    deleteReply() {
      const payload = {
        isUse: false,
      };
      OCPP_API.inquiries
        .deleteReply(payload, this.reply.id)
        .then(() => {
          this.isDeleteAlertOpen = false;
        })
        .then(() => {
          this.inquiry.isReplied = false; // 답변이 있는 경우로 UI를 바꾸기 위함
          this.isReplyOpen = false;
        });
    },

    // 답변수정
    updateReply() {
      const payload = {
        contents: this.contents,
      };
      OCPP_API.inquiries
        .updateReply(payload, this.reply.id)
        .then(() => {
          this.inquiry.isReplied = true; // 답변이 있는 경우로 UI를 바꾸기 위함
          this.isUpdateOpen = false;
        })
        .then(() => {
          this.resetReply();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/utils.scss";
</style>
