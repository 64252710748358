<template>
  <div>
    <v-card>
      <v-card-title class="ml-5 font-weight-bold"> 1:1 문의 </v-card-title>
      <v-card-subtitle class="ml-5 font-weight-regular">
        EV Infra Hub를 사용하는 사업자들의 문의를 확인하고 답변할 수 있습니다.
      </v-card-subtitle>

      <v-card-text>
        <div class="ml-5 mr-5">
          <!-- 검색 종류, 검색어  -->
          <div class="d-flex">
            <div class="d-flex justify-start mt-5">
              <v-btn-toggle v-model="isReplied" tile group>
                <v-btn value="0" @click="editIsReplied(null)"> 전체 </v-btn>
                <v-btn value="1" @click="editIsReplied(false)"> 미답변 </v-btn>
                <v-btn value="2" @click="editIsReplied(true)"> 답변완료 </v-btn>
              </v-btn-toggle>
            </div>
            <div class="d-flex justify-end mt-5 ml-auto">
              <div class="d-flex">
                <Select
                  :selectItems="[
                    { id: 0, name: '문의 종류' },
                    { id: 1, name: '제목+내용' },
                    { id: 2, name: '사업자' },
                  ]"
                  :width="`130px`"
                  :identity="`inquiries`"
                  :placeholder="`검색 조건`"
                  @editSelect="editSearchCondition"
                />

                <Select
                  v-if="selectedSearchCondition === 0"
                  :selectItems="[
                    { id: 0, name: '전체' },
                    { id: 1, name: '과금설정 변경' },
                    { id: 2, name: '충전기 고장/수리완료' },
                    { id: 3, name: '충전기 정보 수정' },
                    { id: 4, name: '사업 제휴' },
                    { id: 5, name: '매출 및 실적' },
                    { id: 6, name: '기타 문의' },
                  ]"
                  :width="`200px`"
                  :identity="`inquiryTypeId`"
                  :placeholder="`문의 종류를 선택해주세요.`"
                  @editInquiryTypeId="editInquiryTypeId"
                />
                <Input
                  v-else-if="selectedSearchCondition !== 0"
                  :placeholder="`내용을 입력하세요.`"
                  :isIcon="true"
                  :width="'300px'"
                  :height="`40px`"
                  @editInput="editSearchStr"
                />
              </div>
            </div>
          </div>

          <!-- 검색 결과 테이블 -->
          <Table
            :headers="headers"
            :list="response.list"
            :itemPerPage="response.size"
            :identity="`inquiries`"
            :isClickRow="true"
            class="mt-2 mb-2"
          />

          <Pagination
            :length="response.totalPage"
            class="align-self-center mr-auto"
            ref="pagination"
            @editPage="editPage"
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- <ErrorHandler ref="errorHandler" /> -->
  </div>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import Table from "../../common/Table.vue";
import Pagination from "../../common/Pagination.vue";
import Input from "../../common/Input.vue";
import Select from "../../common/Select.vue";
// import ErrorHandler from "../../common/ErrorHandler.vue";

export default {
  name: "Inquiries",

  components: {
    Table: Table,
    Pagination: Pagination,
    Input: Input,
    Select: Select,
    // ErrorHandler: ErrorHandler,
  },

  data: () => ({
    // 답변 상태
    isReplied: "0",
    // 테이블 헤더
    headers: [
      {
        text: "답변 상태",
        value: "isReplied",
        width: "8%",
        sortable: false,
      },
      {
        text: "작성자",
        value: "createUserId.fullName",
        width: "8%",
        sortable: false,
      },
      {
        text: "사업자",
        value: "createUserId.company.companyName",
        width: "12%",
        sortable: false,
      },
      {
        text: "문의 종류",
        value: "inquiryTypeId",
        width: "15%",
        sortable: false,
      },
      { text: "제목", value: "title", width: "17%", sortable: false },
      { text: "내용", value: "contents", width: "22%", sortable: false },
      { text: "문의날짜", value: "dateCreated", width: "18%", sortable: false },
    ],
    // 검색 조건
    searchForm: {
      isReplied: null,
      inquiryTypeId: null,
      keyword: "",
      companyKeyword: "",
      page: 1,
      itemsPerPage: 10,
    },
    response: {
      list: [],
      size: 10,
      totalPage: 0,
    },
    // 검색 종류 선택
    selectedSearchCondition: null,
  }),

  methods: {
    // 답변 상태 탭
    editIsReplied(isReplied) {
      this.searchForm.page = 1;
      this.$refs.pagination.page = 1;
      this.searchForm.isReplied = isReplied;
    },

    // 검색 종류 선택
    editSearchCondition(payload) {
      this.selectedSearchCondition = payload.id;
    },

    // 문의 종류 선택(selectedSearchCondition === 0일때)
    editInquiryTypeId(payload) {
      if (this.selectedSearchCondition !== 0) return;

      this.searchForm.keyword = ""; // 검색어 리셋
      this.searchForm.companyKeyword = ""; // 사업자 검색어 리셋
      this.$refs.pagination.page = 1; // Pagination을 1페이지로 리셋
      this.searchForm.page = 1; // searchForm의 page를 1페이지로 리셋
      if (payload.id === 0) {
        // 전체 선택할 경우 null로
        this.searchForm.inquiryTypeId = null;
      } else {
        this.searchForm.inquiryTypeId = payload.id;
      }
    },

    // 검색어 or 사업자
    editSearchStr(payload) {
      if (this.selectedSearchCondition === 0) return;

      this.searchForm.inquiryTypeId = null; // 문의종류 리셋
      this.$refs.pagination.page = 1; // Pagination을 1페이지로 리셋
      this.searchForm.page = 1; // searchForm의 page를 1페이지로 리셋
      if (this.selectedSearchCondition === 1) {
        // 제목+내용
        this.searchForm.companyKeyword = "";
        this.searchForm.keyword = payload;
      } else if (this.selectedSearchCondition === 2) {
        // 사업자
        this.searchForm.keyword = "";
        this.searchForm.companyKeyword = payload;
      }
    },

    // 페이지네이션
    editPage(payload) {
      this.searchForm.page = payload;
    },
  },

  created() {
    OCPP_API.inquiries.search(this.searchForm).then((res) => {
      this.response = {
        list: res.data._embedded.inquiries,
        size: res.data.page.size,
        totalPage: res.data.page.totalPages,
      };
    });
  },

  // data의 searchForm에 변화가 일어날 때마다 handler()
  watch: {
    searchForm: {
      deep: true, // 중첩된 object에 대해서는 변화를 감지하지 못해서 deep true 설정을 해줘야 함
      handler() {
        OCPP_API.inquiries.search(this.searchForm).then((res) => {
          this.response = {
            list: res.data._embedded.inquiries,
            size: res.data.page.size,
            totalPage: res.data.page.totalPages,
          };
        });
        // .catch((err) => {
        //   this.$refs.errorHandler.handleError(err);
        // });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/utils.scss";

// active된 버튼
.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default {
  padding: 12px 10px;
  width: auto;
  height: 40px;
  background: white !important;
  color: black !important;
  border: 1px solid white;
  border-radius: 6px;
}
.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 0; // 버튼 불투명 정도
}

// active 이외 버튼
.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default {
  padding: 12px 10px;
  width: auto;
  height: 40px;
  background: #656565 !important ;
  color: #e0e0e0 !important;
  border-radius: 6px;
}
</style>
