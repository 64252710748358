<template>
  <div class="text-center">
    <v-pagination
      v-model="page"
      :length="length"
      :total-visible="7"
      color="white"
      circle
    ></v-pagination>
  </div>
</template>

<script>
export default {
  emits: ["page"],

  name: "Pagination",

  props: {
    length: { type: Number },
  },

  data() {
    return {
      page: 1,
    };
  },

  watch: {
    page() {
      this.$emit("editPage", this.page);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/utils.scss";
</style>
