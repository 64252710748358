<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="itemPerPage"
      class="common_v-data-table"
      hide-default-footer
      @click:row="detailOpen"
    >
      <!-- 1:1문의 - 답변 상태 -->
      <template v-slot:[`item.isReplied`]="{ item }">
        <v-chip
          v-if="item.isReplied"
          small
          text-color="white"
          :color="`#18CC8B`"
          >답변 완료</v-chip
        >
        <v-chip v-else small text-color="black" :color="`#D7F9E8`"
          >미답변</v-chip
        >
      </template>

      <!-- 1:1문의 - 문의 종류 id값 -> name으로 변경 -->
      <template v-slot:[`item.inquiryTypeId`]="{ item }">
        {{
          item.inquiryTypeId === 1
            ? "과금설정 변경"
            : item.inquiryTypeId === 2
            ? "충전기 고장/수리완료"
            : item.inquiryTypeId === 3
            ? "충전기 정보 수정"
            : item.inquiryTypeId === 4
            ? "사업 제휴"
            : item.inquiryTypeId === 5
            ? "매출 및 실적"
            : "기타 문의"
        }}
      </template>

      <!-- 1:1문의 - 문의날짜 -->
      <template v-slot:[`item.dateCreated`]="{ item }">{{
        dateFormat(item.dateCreated)
      }}</template>
    </v-data-table>

    <v-dialog v-model="isInquiryDetailOpen" width="60%">
      <Inquiry ref="inquiry" @close="detailClose" />
    </v-dialog>
  </div>
</template>

<script>
import Inquiry from "../contents/inquiry/Inquiry.vue";

export default {
  name: "Table",

  props: {
    headers: { type: Array },
    list: { type: Array },
    itemPerPage: { type: Number },
    identity: { type: String },
    isClickRow: { type: Boolean },
    searchStart: { type: String },
    searchEnd: { type: String },
    searchRange: { type: Number },
  },

  components: {
    Inquiry: Inquiry,
  },

  data: () => ({
    isInquiryDetailOpen: false,
  }),

  methods: {
    // 행 클릭시 상세 모달 오픈
    detailOpen(item) {
      // 위에 @click:row에서 item 정보를 명시하여 넘기지 않아도 parameter로 받을 수 있음
      // props로 넘겨받은 isClickRow가 false면 안눌리게
      if (this.isClickRow === false) return;

      if (this.identity === "inquiries") {
        this.isInquiryDetailOpen = true;

        setTimeout(() => {
          this.$refs.inquiry.open(item);
        }, 100);
      }
    },

    detailClose() {
      if (this.identity === "inquiries") this.isInquiryDetailOpen = false;
    },

    dateFormat(UTC) {
      let date = new Date(UTC);
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour >= 10 ? hour : "0" + hour;
      minute = minute >= 10 ? minute : "0" + minute;
      second = second >= 10 ? second : "0" + second;

      return (
        date.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/utils.scss";

// 모달
.v-dialog {
  &::-webkit-scrollbar {
    display: none !important; // 크롬에서 스크롤 기능은 유지 바는 제거
  }
}

.common_v-data-table {
  border-radius: 0;
  .v-data-table__wrapper {
    > table .v-data-table-header {
      background-color: #656565;
      > tr > th {
        text-align: center !important; // 가운데 정렬
        padding: 0;
        > span {
          color: #ffffff;
        }
      }
    }
    > table > tbody > tr :hover {
      cursor: pointer; // 마우스 커서
    }
    > table {
      table-layout: fixed; // td태그 안에 글자수 제한
    }
    > table > tbody > tr > td {
      overflow: hidden; // td태그 안에 글자수 제한
      white-space: nowrap; // td태그 안에 글자수 제한
      text-overflow: ellipsis; // td태그 안에 글자수 제한
      text-align: center !important; // 가운데 정렬
      padding: 0;
      font-size: 13px;
      // 과금 여부 체크 아이콘
      .v-icon.v-icon {
        font-size: 18px; // 아이콘 사이즈 조정
      }
    }
  }
}

.v-chip {
  padding: 4px 8px !important;
}
</style>
