<template>
  <v-select
    class="common_v-select"
    :items="selectItems"
    item-text="name"
    item-value="id"
    :style="cssProps"
    :placeholder="placeholder"
    @change="selected"
    solo
    dense
    light
    hide-details
    return-object
  ></v-select>
</template>

<script>
export default {
  name: "Select",

  props: {
    selectItems: { type: Array },
    width: { type: String },
    identity: { type: String },
    placeholder: { type: String },
  },

  computed: {
    cssProps() {
      return {
        // --변수명: 값; 포맷으로 변수를 선언하고(스크립트 단에서 동적으로 정하고 싶음) 아래 style태그에서 var(--변수명)으로 사용 가능
        "--width": this.width,
      };
    },
  },

  methods: {
    selected(event) {
      if (this.identity === "inquiryTypeId") {
        // 1:1 문의 조회에서 사용
        this.$emit("editInquiryTypeId", event);
      } else {
        this.$emit("editSelect", event);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/utils.scss";

.common_v-select {
  width: var(--width);
  height: 40px;
  border-radius: 6px;
  // border: thin solid $textfield-border-gray;

  .v-icon {
    font-size: 15px;
    color: $black;
  }
}

.v-text-field.v-text-field--enclosed {
  margin: 5px 3px;
  padding: 0;
}

// font-size 설정
.v-input__control {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 13px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}

// select box 펼쳤을 때 box-shadow 설정
.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  border: thin solid $textfield-border-gray;
  // box-shadow: 1px 1px 3px 3px $lightest-gray;
  border-radius: 4px;
}

// select box 펼쳤을 때
.v-application .primary--text {
  // color: $gray !important;
  // background-color: $textfield-background-gray !important;
}
</style>
