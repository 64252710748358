<template>
  <div>
    <!-- 일반 텍스트 버튼 -->
    <v-btn @click="clicked" class="common_v-btn" :style="cssProps"
      >{{ btnName }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Button",

  props: {
    btnName: { type: String },
    backgroundColor: { type: String },
    color: { type: String },
    borderColor: { type: String },
    width: { type: String },
    height: { type: String },
    fontWeight: { type: String },
    fontSize: { type: String },
    path: { type: String },
  },

  computed: {
    cssProps() {
      return {
        "--background-color": this.backgroundColor,
        "--color": this.color,
        "--borderColor": this.borderColor,
        "--width": this.width,
        "--height": this.height,
        "--fontWeight": this.fontWeight,
        "--fontSize": this.fontSize,
      };
    },
  },

  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/utils.scss";

.theme--light.v-btn.v-btn--has-bg {
  background-color: var(--background-color) !important;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: var(--background-color) !important;
}

.v-btn.common_v-btn {
  border: 1px var(--borderColor) solid;
  box-shadow: none;
  width: var(--width) !important;
  height: var(--height) !important;
  margin: 0 5px 0 5px;
  border-radius: 6px;

  // 버튼 텍스트 관련
  color: var(--color) !important;
  font-size: var(--fontSize) !important;
  font-weight: var(--fontWeight) !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: var(--height);
  min-width: 44px; // 1:1문의 '전체'버튼 width가 가장 작음
  padding: 0 16px;
}
</style>
