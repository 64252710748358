<template>
  <v-text-field
    v-model="text"
    class="common_v-text-field"
    type="text"
    @change="editInput"
    @keyup.enter="editInput(text)"
    @click:clear="clearInput"
    :prepend-inner-icon="isIcon ? 'mdi-magnify' : ''"
    :placeholder="placeholder"
    :style="cssProps"
    solo
    dense
    light
    hide-details
    clearable
  ></v-text-field>
</template>

<script>
export default {
  name: "Input",

  props: {
    placeholder: { type: String },
    isIcon: { type: Boolean },
    width: { type: String },
    height: { type: String },
  },

  computed: {
    cssProps() {
      return {
        "--width": this.width,
        "--height": this.height,
      };
    },
  },

  data: () => ({
    text: "",
  }),

  methods: {
    editInput(event) {
      this.$emit("editInput", event);
    },

    // clearable 클릭 시 전체 검색 @click:clear="clearInput"
    clearInput() {
      this.$emit("editInput", "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/utils.scss";

// box shadow none
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}

.common_v-text-field {
  width: var(--width);
  height: var(--height);
  border-radius: 6px;
  border: thin solid $textfield-border-gray;

  .v-input__control {
    height: 400px !important;
  }

  .v-icon {
    font-size: 18px;
    color: $border;
  }
}
</style>
