<template>
  <v-card class="pt-14 px-12 pb-8" style="border-radius: 16px" light>
    <!-- html태그를 반영시키기 위해 콧수염괄호 대신 v-html사용 -->
    <span
      v-html="getMessage"
      class="d-flex justify-center text-center mb-4"
      style="font-size: 24px; font-weight: 700"
    />
    <v-card-actions>
      <Button
        :btnName="btnName1"
        :backgroundColor="`white`"
        :color="`black`"
        :borderColor="`#E1E1E3`"
        :width="`131px`"
        :height="`50px`"
        :fontWeight="`400`"
        :fontSize="`16px`"
        @click="close"
      />
      <Button
        :btnName="btnName2"
        :backgroundColor="backgroundColor"
        :color="`white`"
        :borderColor="backgroundColor"
        :width="`131px`"
        :height="`50px`"
        :fontWeight="`800`"
        :fontSize="`16px`"
        @click="clicked"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import Button from "./Button.vue";

export default {
  name: "Alert",

  props: {
    message: { type: String },
    btnName1: { type: String },
    btnName2: { type: String },
    backgroundColor: { type: String },
    to: { type: String },
  },

  computed: {
    // 개행 적용하기 위함
    getMessage() {
      return this.message.replaceAll("\n", "<br />");
    },
  },

  components: {
    Button: Button,
  },

  methods: {
    // 닫는 메소드
    close() {
      this.$emit("close");
    },

    // 액션이 필요한 클릭
    clicked() {
      this.$emit("action");
    },

    // 라우터 이동이 필요할 때
    // goTo(to) {
    //   // reload가 필요하거나 라우터이동없이 alert을 닫기만 하고 싶을 때
    //   if (to == "reload") {
    //     window.location.reload();
    //     return;
    //   } else if (to == undefined) {
    //     this.clicked();
    //     return;
    //   } else if (to == "logout") {
    //     this.$emit("logout");
    //   }
    //   this.$router.push({ name: to });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/utils.scss";

// message
.v-card__text {
  text-align: center;
  font-size: 1.1vw;
}

// message padding
.v-dialog > .v-card > .v-card__text {
  padding: 40px 24px;
}

// 확인 버튼
.v-card__actions {
  display: flex;
  justify-content: center;
}

// 확인 버튼 padding
.v-dialog > .v-card > .v-card__actions {
  padding: 16px;
}
</style>
